
import { Component, Vue } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';

@Component({
    components: {
        SvgIcon,
    },
})
export default class LevelOneSuccess extends Vue {}
